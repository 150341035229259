<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <v-preview
      :show="isPreview"
      :closePreivew="closePreview"
      :object="previewObject"
    />

    <v-alert-error :show="isError" :close="closeAlert"></v-alert-error>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0;"
          t-data="headline-row"
        >
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} News
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="isPreviewButtonDisable"
            :on-remove="showDeletePopup"
            :on-cancel="showCancelPopup"
            :on-preview="onPreview"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title class="mt-0 pt-0 pb-0">
        <h6 class="mb-0 card-title" t-data="card-title">
          News Information
        </h6>
        <v-spacer></v-spacer>
        <p class="edit-field-label sub-body-bold mr-4" t-data="info-label">
          Status
        </p>
        <v-switch
          color="primary"
          v-model="newsObject.status"
          :false-value="1"
          :true-value="0"
          class="mb-0 pb-0"
          :disabled="disabledEdit"
        ></v-switch>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-0">
        <v-form ref="form" lazy-validation>
          <v-row align="start" justify="start" class="mt-0 mb-4">
            <v-col cols="6" class="mt-0 pt-0" t-data="info-input">
              <single-selected-branch
                outlined
                dense
                title="Branch <span class='danger500Color--text'>*</span>"
                :branch="orgObject"
                :selectedBranch="selectedBranch"
                :syncData="syncSelectedData"
                :isEditMode="isEditMode"
                v-if="!isLoading"
                :isDisabled="disabledEdit"
                @change="resetCategory"
              ></single-selected-branch>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Category <span class="danger500Color--text">*</span>
              </p>
              <v-select
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select Category"
                v-model="newsObject.category_id"
                :menu-props="{bottom: true, offsetY: true}"
                :items="categoryItems"
                :rules="[v => !!v || 'Category is required']"
                :disabled="categoryItems.length <= 0 || disabledEdit"
                @input="handleCategoryChange"
              ></v-select>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Publish Start Date <span class="danger500Color--text">*</span>
              </p>
              <custom-date-picker
                placeholder="Select Start Date"
                v-model="newsObject.period_start_date"
                @input="startDateChange"
                :is-disable="isDisableAfterPublish || disabledEdit"
                outlined
                required
              ></custom-date-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Start Time <span class="danger500Color--text">*</span>
              </p>
              <time-picker
                placeholder="Select Start Time"
                v-model="newsObject.period_start_time"
                @input="startTimeChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[
                  v => !!v || 'Time is required',
                  v => startTimeRule(v)
                ]"
                outlined
                required
              ></time-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Publish End Date <span class="danger500Color--text">*</span>
              </p>
              <custom-date-picker
                placeholder="Select End Date"
                v-model="newsObject.period_end_date"
                @input="endDateChange"
                :allowedDates="allowedDates"
                :is-disable="disabledEdit"
                outlined
                required
              ></custom-date-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                End Time <span class="danger500Color--text">*</span>
              </p>
              <time-picker
                placeholder="Select End Time"
                v-model="newsObject.period_end_time"
                :isDisable="disabledEdit"
                :rule="[
                  v => !!v || 'Time is required',
                  v => endTimeRule(v)
                ]"
                outlined
                required
              ></time-picker>
            </v-col>
          </v-row>

          <!-- Contents -->
          <!-- Thai -->
          <v-expansion-panels :value="0" class="mb-4">
            <v-expansion-panel outlined>
              <v-expansion-panel-header
                @click="checkThaiContentExpand"
                disable-icon-rotate
              >
                <h6 class="mb-0 card-title" t-data="info-label">
                  Thai Content
                </h6>
                <template v-slot:actions>
                  <v-icon color="primary">
                    {{ isThaiContentExpand ? '$minus' : '$plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col cols="6" t-data="info-input">
                      <p
                        class="edit-field-label sub-body-bold"
                        t-data="info-label"
                      >
                        Image <span class="danger500Color--text">*</span>
                      </p>
                      <div
                        t-data="image-thai-container"
                        :class="
                          isImageThError
                            ? 'image-upload-box-error text-center d-flex flex-column align-center align-self-center align-content-center justify-center'
                            : 'image-upload-box text-center d-flex flex-column align-center align-self-center align-content-center justify-center'
                        "
                      >
                        <span
                          t-data="image-remove-button"
                          v-if="
                            newsObject.contents[0].thumbnail && !disabledEdit
                          "
                          class="ma-2 image-remove-button"
                          color="whiteColor"
                          @click="removeImageThaiImage"
                        >
                          <v-icon light>
                            mdi-close
                          </v-icon>
                        </span>
                        <v-img
                          v-show="newsObject.contents[0].thumbnail"
                          :src="newsObject.contents[0].thumbnail"
                          aspect-ratio="4"
                          contain
                          class="preview-uploaded-image"
                        ></v-img>
                        <input
                          v-if="!newsObject.contents[0].thumbnail"
                          ref="inputThaiRef"
                          type="file"
                          accept="image/png, image/jpeg"
                          class="input-file"
                          @change="updateImageThai"
                          @click:clear="updateImageThai"
                          :disabled="disabledEdit"
                          required
                        />
                        <div
                          v-if="!newsObject.contents[0].thumbnail"
                          class="text-center d-flex flex-column align-center align-self-center align-content-center justify-center"
                        >
                          <p class="image-upload-box-title">
                            Drop your image here or
                            <span class="input-file-text">browse</span>
                          </p>
                          <p
                            class="image-upload-box-info-title-bold sub-body-bold mb-0"
                          >
                            Recommemded image size 320x240px.
                          </p>
                          <p
                            class="image-upload-box-info-title sub-body-regular"
                          >
                            (Support .jpg and .png files. Up to 1 mb. max.)
                          </p>
                        </div>
                      </div>
                      <div class="sales-price-preview-box-error-message">
                        <v-row
                          align="center"
                          justify="start"
                          class="flex-nowrap"
                          v-show="isImageThError"
                        >
                          <p
                            style="marginLeft:10px"
                            class="edit-error-message sub-body-regular mb-0"
                          >
                            {{ imageThErrorMessage }}
                          </p>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col cols="6" t-data="info-input">
                      <p class="edit-field-label sub-body-bold">Title <span class="danger500Color--text">*</span></p>
                      <v-text-field
                        class="can-disable-selector"
                        placeholder="Content Title"
                        outlined
                        dense
                        required
                        :rules="[titleThaiRules.required]"
                        v-model="newsObject.contents[0].title"
                        @change="duplicateThaiContentChange()"
                        :disabled="disabledEdit"
                      ></v-text-field>
                      <quill-editor
                        ref="quillTh"
                        v-model="newsObject.contents[0].description"
                        :options="editorOption"
                        @blur="duplicateThaiContentChange()"
                      ></quill-editor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- English -->
          <v-expansion-panels :value="0">
            <v-expansion-panel>
              <v-expansion-panel-header
                @click="checkEngContentExpand"
                disable-icon-rotate
              >
                <v-row class="mr-0 ml-0">
                  <h6 class="mb-0 card-title pr-3" t-data="info-label">
                    English Content
                  </h6>
                  <v-checkbox
                    v-model="duplicateContent"
                    id="check-featured-product"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiContent"
                    :disabled="disabledEdit"
                  ></v-checkbox>
                </v-row>

                <template v-slot:actions>
                  <v-icon color="primary">
                    {{ isEngContentExpand ? '$minus' : '$plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col cols="6" t-data="info-input">
                      <p
                        class="edit-field-label sub-body-bold"
                        t-data="info-label"
                      >
                        Image <span class="danger500Color--text">*</span>
                      </p>
                      <div
                        t-data="image-eng-container"
                        :class="
                          isImageEngError
                            ? 'image-upload-box-error text-center d-flex flex-column align-center align-self-center align-content-center justify-center'
                            : 'image-upload-box text-center d-flex flex-column align-center align-self-center align-content-center justify-center'
                        "
                      >
                        <span
                          t-data="image-remove-button"
                          v-if="
                            newsObject.contents[1].thumbnail && !disabledEdit
                          "
                          class="ma-2 image-remove-button"
                          color="whiteColor"
                          @click="removeImageEngImage"
                        >
                          <v-icon light>
                            mdi-close
                          </v-icon>
                        </span>
                        <v-img
                          v-show="newsObject.contents[1].thumbnail"
                          :src="newsObject.contents[1].thumbnail"
                          aspect-ratio="4"
                          contain
                          class="preview-uploaded-image"
                        ></v-img>
                        <input
                          v-if="!newsObject.contents[1].thumbnail"
                          ref="inputEngRef"
                          type="file"
                          accept="image/png, image/jpeg"
                          class="input-file"
                          @change="updateImageEng"
                          @click:clear="updateImageEng"
                          :disabled="disabledEdit"
                          required
                        />
                        <div
                          v-if="!newsObject.contents[1].thumbnail"
                          class="text-center d-flex flex-column align-center align-self-center align-content-center justify-center"
                        >
                          <p class="image-upload-box-title">
                            Drop your image here or
                            <span class="input-file-text">browse</span>
                          </p>
                          <p
                            class="image-upload-box-info-title-bold sub-body-bold mb-0"
                          >
                            Recommemded image size 320x240px.
                          </p>
                          <p
                            class="image-upload-box-info-title sub-body-regular"
                          >
                            (Support .jpg and .png files. Up to 1 mb. max.)
                          </p>
                        </div>
                      </div>
                      <div class="sales-price-preview-box-error-message">
                        <v-row
                          align="center"
                          justify="start"
                          class="flex-nowrap"
                          v-show="isImageEngError"
                        >
                          <p
                            style="marginLeft:10px"
                            class="edit-error-message sub-body-regular mb-0"
                          >
                            {{ imageEngErrorMessage }}
                          </p>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col cols="6" t-data="info-input">
                      <p
                        class="edit-field-label sub-body-bold"
                        t-data="info-label"
                      >
                        Title <span class="danger500Color--text">*</span>
                      </p>
                      <v-text-field
                        class="can-disable-selector"
                        placeholder="Content Title"
                        outlined
                        dense
                        required
                        :rules="[titleEngRules.required]"
                        v-model="newsObject.contents[1].title"
                        :disabled="disabledEdit"
                      ></v-text-field>
                      <quill-editor
                        id="en"
                        v-model="newsObject.contents[1].description"
                        :options="editorOption"
                        ref="quillEn"
                      ></quill-editor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row
      class="d-flex align-start mb-3"
      t-data="footer-row"
      style="margin-left: 1px; margin-right: 0;"
    >
      <created-detail
        v-if="isEditMode"
        :data-object="newsObject"
      />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="isPreviewButtonDisable"
        :on-remove="showDeletePopup"
        :on-cancel="showCancelPopup"
        :on-preview="onPreview"
        :on-save="onSave"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {mapState, mapActions} from 'vuex'
// import moment from 'moment'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import AlertSuccess from '../../components/AlertSuccess'
import AlertConfirm from '../../components/AlertConfirm'
import moment from 'moment'
import Preview from '../../components/Preview'
import CreatedDetail from '../../components/CreatedDetail'
import ImageUploader from 'quill-image-uploader'
import MyLink from '../../constants/quill-link'
Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)
import SingleSelectedBranch from '../../components/SingleSelectedBranch.vue'
import {
  convertFileToBase64,
  convertDate,
  hasPermissionsByOrgs,
  isUrl
} from '../../helper/helper'
import CustomDatePicker from '@/components/DatePickerWithMin'
import {actionEnum} from '@/constants/constants'
import EditButtons from '@/components/EditButtons'
import TimePicker from '@/components/TimePicker'
import {timeMoreThanCurrentRule, timeMoreThanStartRule} from '@/helper/rule'

export default {
  components: {
    'custom-date-picker': CustomDatePicker,
    'v-alert-confirm': AlertConfirm,
    'v-alert-error': AlertError,
    'v-alert-success': AlertSuccess,
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    CreatedDetail,
    EditButtons,
    SingleSelectedBranch,
    TimePicker,
    quillEditor,
  },
  name: 'index',
  data() {
    return {
      isEditMode: false,
      isDuplicateMode: false,
      isEngContentExpand: true,
      isThaiContentExpand: true,
      isTitleErrorEng: true,
      isTitleErrorThai: true,
      titleEngRules: {
        required: value => {
          if (value) {
            this.isTitleErrorEng = false
            return true
          }
          this.isTitleErrorEng = true
          return 'Title is required'
        }
      },
      titleThaiRules: {
        required: value => {
          if (value) {
            this.isTitleErrorThai = false
            return true
          }
          this.isTitleErrorThai = true
          return 'Title is required'
        }
      },
      subTitle:
        'Are you sure you want to remove news? This action will remove content in both Thai and English.',
      duplicateContent: false,
      isCancel: false,
      isPreview: false,
      isImageEngError: false,
      imageEngErrorMessage: '',
      isImageThError: false,
      imageThErrorMessage: '',
      categoryId: '',
      categoryItems: [],
      isError: false,
      isSuccess: false,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      }
    }
  },
  computed: {
    disabledEdit() {
      return (
        this.isEditMode && !hasPermissionsByOrgs('CanEditNews', [this.selectedBranch])
      )
    },
    hasPermDelete() {
      const orgCodenames =
        this.newsObject.orgs.length > 0
          ? this.newsObject.orgs.filter(o => o.selected).map(o => o.codename)
          : []
      return hasPermissionsByOrgs('CanDeleteNews', orgCodenames)
    },
    selectedBranch() {
      let selectedBranch = null
      const branchNames = this.newsObject.orgs
        .map(({codename, selected}) => (selected ? codename : null))
        .filter(v => v !== null)
      if (branchNames.length > 0) {
        selectedBranch = branchNames[0]
      }
      return selectedBranch
    },
    previewObject() {
      let previewObject = this.newsObject
      previewObject.content = this.newsObject.contents
      return previewObject
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'News', links: true, to: '/news-list'},
        {text: this.textMode, links: false}
      ]
    },
    cachImage: function(image) {
      return `${image}?${new Date().getTime()}`
    },
    isRequired() {
      return (
        this.newsObject.category_id === 0 ||
        this.newsObject.orgs.length <= 0 ||
        this.newsObject.period_start_date === null ||
        this.newsObject.period_end_date === null ||
        this.isTitleErrorEng ||
        this.isTitleErrorThai ||
        this.newsObject.contents[0].thumbnail === '' ||
        this.newsObject.contents[1].thumbnail === ''
      )
    },
    isPreviewButtonDisable() {
      return this.isRequired
    },
    ...mapState('news', [
      'category',
      'duplicateId',
      'isDelete',
      'isDisableAfterPublish',
      'isLoading',
      'newsObject',
      'orgObject',
    ])
  },
  destroyed() {
    this.isSuccess = true
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.isDuplicateMode = !!this.duplicateId
    this.setEditMode(this.isEditMode)
    if (this.isEditMode) {
      await this.setNewsId(this.$route.params.id)
      // scroll to top
      window.scrollTo(0, 0)
    } else if (this.isDuplicateMode) {
      await this.setDuplicateNewsId(this.duplicateId)
    } else {
      this.resetState()
    }

    const orgCodenames =
      this.newsObject.orgs.length > 0
        ? this.newsObject.orgs.filter(o => o.selected).map(o => o.codename)
        : []
    const isViewOnly =
      this.isEditMode && !hasPermissionsByOrgs('CanEditNews', orgCodenames)
    await this.setOrgInfo(isViewOnly)
  },
  watch: {
    disabledEdit() {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    },
    category() {
      const categories = this.category.filter(function(obj) {
        return obj.text != 'All'
      })
      if (categories.length > 0) {
        this.categoryItems = categories
      }
    }
  },
  mounted() {
    if (this.disabledEdit) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  methods: {
    disableQuill() {
      this.$refs.quillTh.quill.enable(false)
      this.$refs.quillEn.quill.enable(false)
      this.$refs.quillTh.quill.root.dataset.placeholder = ''
      this.$refs.quillEn.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillTh.quill.enable(true)
      this.$refs.quillEn.quill.enable(true)
      this.$refs.quillTh.quill.root.dataset.placeholder = 'Insert text here ...'
      this.$refs.quillEn.quill.root.dataset.placeholder = 'Insert text here ...'
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({name: 'news-list'})
    },
    allowedDates(val) {
      return moment(this.newsObject.period_start_date).isSameOrBefore(
        moment(val)
      )
    },
    startTimeRule(val) {
      return this.isDisableAfterPublish
      || this.disabledEdit ? true :
        timeMoreThanCurrentRule(val, this.newsObject.period_start_date)
    },
    endTimeRule(val) {
      return timeMoreThanStartRule(
        val,
        this.newsObject.period_start_time,
        this.newsObject.period_start_date,
        this.newsObject.period_end_date
      )
    },
    checkThaiContentExpand() {
      if (this.isThaiContentExpand) {
        this.isThaiContentExpand = false
      } else {
        this.isThaiContentExpand = true
      }
    },
    checkEngContentExpand() {
      if (this.isEngContentExpand) {
        this.isEngContentExpand = false
      } else {
        this.isEngContentExpand = true
      }
    },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.newsObject.contents[1].title = this.newsObject.contents[0].title
        this.newsObject.contents[1].description = this.newsObject.contents[0].description
        this.newsObject.contents[1].thumbnail = this.newsObject.contents[0].thumbnail
        if (this.newsObject.contents[0].thumbnail == '') {
          this.isImageEngError = true
          this.imageEngErrorMessage = 'Image is required'
        } else {
          this.isImageEngError = false
          this.imageEngErrorMessage = ''
        }
      } else {
        this.newsObject.contents[1].title = null
        this.newsObject.contents[1].description = ''
        this.newsObject.contents[1].thumbnail = ''
      }
    },
    duplicateThaiContentChange() {
      if (this.duplicateContent) {
        this.newsObject.contents[1].title = this.newsObject.contents[0].title
        this.newsObject.contents[1].description = this.newsObject.contents[0].description
        this.newsObject.contents[1].thumbnail = this.newsObject.contents[0].thumbnail
      }
    },
    removeImageEngImage() {
      this.newsObject.contents[1].thumbnail = ''
      this.isImageEngError = true
      this.imageEngErrorMessage = 'Image is required'
    },
    removeImageThaiImage() {
      this.newsObject.contents[0].thumbnail = ''
      if (this.duplicateContent) {
        this.newsObject.contents[1].thumbnail = ''
        this.isImageEngError = true
        this.imageEngErrorMessage = 'Image is required'
      }
      this.isImageThError = true
      this.imageThErrorMessage = 'Image is required'
    },
    startDateChange(date) {
      if (date) {
        this.newsObject.period_start_time = ''
        this.newsObject.period_end_date = null
        this.newsObject.period_end_time = ''
      }
    },
    startTimeChange(event) {
      if (event) {
        this.newsObject.period_end_time = ''
      }
    },
    endDateChange(event) {
      if (event) {
        this.newsObject.period_end_time = ''
      }
    },
    handleCategoryChange(event) {
      this.categoryId = event
    },
    async confirmDelete() {
      const status = await this.deletePkg()
      if (status) {
        this.goToNewsList(true, actionEnum.REMOVED)
        this.isSuccess = true
      } else {
        this.isError = true
      }
    },
    showDeletePopup() {
      this.setDeleteId(this.newsObject.id)
      this.setDeletePopup(true)
    },
    onPreview() {
      this.isPreview = true
    },
    goToNewsList(isCreateSuccess, snackTitle) {
      this.$router.push({
        name: 'news-list',
        query: {isCreateSuccess, snackTitle}
      })
    },
    async onSave() {
      if (this.validate()) {
        if (this.isEditMode) {
          const status = await this.updatePkg({
            id: this.$route.params.id,
            pkg: this.newsObject
          })

          if (status) {
            this.goToNewsList(true, actionEnum.EDITED)
            return (this.isSuccess = true)
          } else {
            return (this.isError = true)
          }
        } else {
          const status = await this.create(this.newsObject)
          if (status) {
            this.goToNewsList(true, actionEnum.CREATED)
            return (this.isSuccess = true)
          } else {
            return (this.isError = true)
          }
        }
      }
    },
    imageEngRules(val) {
      if (this.isEditMode) {
        this.isImageEngError = false
        this.imageEngErrorMessage = ''

        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.isImageEngError = true
        this.imageEngErrorMessage = 'Image is required'
        return
      } else {
        if (!val || val === '') {
          this.isImageEngError = true
          this.imageEngErrorMessage = 'Image is required'
          return
        }
        this.isImageEngError = false
        this.imageEngErrorMessage = ''
        return
      }
    },
    imageThaiRules(val) {
      if (this.isEditMode) {
        this.isImageThError = false
        this.imageThErrorMessage = ''
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.isImageThError = true
        this.imageThErrorMessage = 'Image is required'
        return
      } else {
        if (!val || val === '') {
          this.isImageThError = true
          this.imageThErrorMessage = 'Image is required'
          return
        }

        this.isImageThError = false
        this.imageThErrorMessage = ''
        return
      }
    },
    updateImageThai(e) {
      const target = e.target
      const file = target.files[0]

      if (file && file.name) {
        if (this.isEditMode) {
          if (!String(file.type).includes('image')) {
            this.isImageThError = true
            this.imageThErrorMessage = 'Only support image file!'
            return
          } else if (!file || file.size > 1000000) {
            this.isImageThError = true
            this.imageThErrorMessage = 'Image size should be less than 1 MB!'
            return
          } else {
            this.setImageThai(file)
            this.duplicateContent && this.updateImageEng(e)
            this.isImageThError = false
            this.imageThErrorMessage = ''
            return
          }
        } else {
          if (!file || file.size > 1000000) {
            this.isImageThError = true
            this.imageThErrorMessage = 'Image size should be less than 1 MB!'
            return
          } else {
            this.setImageThai(file)
            this.duplicateContent && this.updateImageEng(e)
            this.isImageThError = false
            this.imageThErrorMessage = ''
            return
          }
        }
      } else {
        this.newsObject.contents[0].thumbnail = ''
      }
    },
    updateImageEng(e) {
      const target = e.target
      const file = target.files[0]

      if (file && file.name) {
        if (this.isEditMode) {
          if (!String(file.type).includes('image')) {
            this.isImageEngError = true
            this.imageEngErrorMessage = 'Only support image file!'
            return
          } else if (!file || file.size > 1000000) {
            this.isImageEngError = true
            this.imageEngErrorMessage = 'Image size should be less than 1 MB!'
            return
          } else {
            this.setImageEng(file)
            this.isImageEngError = false
            this.imageEngErrorMessage = ''
            return
          }
        } else {
          if (!file || file.size > 1000000) {
            this.isImageEngError = true
            this.imageEngErrorMessage = 'Image size should be less than 1 MB!'
            return
          } else {
            this.setImageEng(file)
            this.isImageEngError = false
            this.imageEngErrorMessage = ''
            return
          }
        }
      } else {
        this.newsObject.contents[1].thumbnail = ''
      }
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    validate() {
      this.imageThaiRules(this.newsObject.contents[0].thumbnail)
      this.imageEngRules(this.newsObject.contents[1].thumbnail)

      if (
        this.$refs.form.validate() &&
        !this.isImageThError &&
        !this.isImageEnError
      ) {
        return true
      } else {
        return false
      }
    },
    resetCategory() {
      this.handleCategoryChange('')
    },
    syncSelectedData(data) {
      if (data.length <= 0) {
        // reset category
        this.newsObject.category_id = 0
      }
      this.newsObject.orgs = data.map(org => ({
        codename: org,
        selected: true
      }))
      this.setOrgFilterCategory(data)
      this.fetchCategory()
    },
    convertDate(date) {
      return convertDate(date)
    },
    ...mapActions('news', [
      'create',
      'deletePkg',
      'fetchCategory',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setDuplicateNewsId',
      'setEditMode',
      'setImageEng',
      'setImageThai',
      'setNewsId',
      'setOrgFilterCategory',
      'setOrgInfo',
      'updatePkg',
      'upload'
    ])
  }
}
</script>
